import type { ColorDefinition } from './color-types';

const GoldenV2Palette = {
  Dark300: '#162A38', // Used as a dimmed color on floating surfaces
  Dark500: '#05161D', // Background
  Dark900: '#050b0f',
  Black: '#000406',

  White: '#FFFFFF',
  Porcelain: '#D0D7DA',
  Stone: '#90a8a5',

  Yellow: '#FFCF40',

  Gold1: '#FFD559',
  Gold2: '#C2A140',
  Gold3: '#A17C32',

  Turquoise: '#13EFCF',
  TurquoiseIce: '#29C6DE',
  TurquoiseSky: '#00C9D9',
  TurquoiseFaded: '#0c8175', // Turquoise blended with Dark

  Peach: '#FF936A',
  BleachedPeach: '#FFC6B4',
};

const Primitive: ColorDefinition['Primitive'] = {
  Primary: GoldenV2Palette.Yellow,
  PrimaryTint: GoldenV2Palette.Yellow,
  PrimaryContrast: GoldenV2Palette.Dark500,

  Secondary: GoldenV2Palette.Turquoise,
  SecondaryTint: GoldenV2Palette.Turquoise,
  SecondaryContrast: GoldenV2Palette.Dark500,

  Accent: GoldenV2Palette.Turquoise,
  AccentTint: GoldenV2Palette.Turquoise,
  AccentContrast: GoldenV2Palette.Dark500,

  Gradient: `linear-gradient(180deg, ${GoldenV2Palette.Gold1} 0%, ${GoldenV2Palette.Gold2} 69%, ${GoldenV2Palette.Gold3} 100%) border-box`,
  GradientTint: `linear-gradient(182deg, ${GoldenV2Palette.Gold1} 0%, ${GoldenV2Palette.Gold2} 69%, ${GoldenV2Palette.Gold3} 100%) border-box`,
  GradientContrast: GoldenV2Palette.Dark500,
};

const TextColors: ColorDefinition['TextColors'] = {
  HeadingText: GoldenV2Palette.White,
  BodyText: GoldenV2Palette.Porcelain,
  MutedText: GoldenV2Palette.Stone,
  HighlightedText: GoldenV2Palette.White,
  LinkText: GoldenV2Palette.Turquoise,
  ErrorText: GoldenV2Palette.White,
  DisabledText: GoldenV2Palette.Stone + '80',
};

const Surface: ColorDefinition['Surface'] = {
  Base: {
    Background: GoldenV2Palette.Dark900,
    Foreground: TextColors.BodyText,
    Dimmed: GoldenV2Palette.Dark300,
  },
  Nested: {
    Background: GoldenV2Palette.Black,
    Foreground: TextColors.BodyText,
    Dimmed: GoldenV2Palette.Dark300,
  },
  Disabled: {
    Background: '#4b5458',
    Foreground: '#8f9393',
    Dimmed: '#707679',
  },
  Floating: {
    Background: GoldenV2Palette.Dark500,
    Foreground: TextColors.BodyText,
    Dimmed: GoldenV2Palette.Dark300,
  },
};

const Signal: ColorDefinition['Signal'] = {
  Success: GoldenV2Palette.Turquoise,
  SuccessContrast: GoldenV2Palette.Dark500,

  Info: GoldenV2Palette.Turquoise,
  InfoContrast: GoldenV2Palette.Dark500,

  Attention: GoldenV2Palette.BleachedPeach,
  AttentionContrast: GoldenV2Palette.Dark500,

  Danger: GoldenV2Palette.Peach,
  DangerContrast: GoldenV2Palette.Dark500,
};

const CornerRadius: ColorDefinition['CornerRadius'] = {
  Small: '4px',
  Base: '8px',
  Large: '32px',
};

const Elevation: ColorDefinition['Elevation'] = {
  Level1: `0 2px 3px -1px var(--shadow-color, ${GoldenV2Palette.Black}20)`,
  Level2: `0 4px 6px var(--shadow-color, ${GoldenV2Palette.Black}20)`,
  Level3: `0 8px 12px var(--shadow-color, ${GoldenV2Palette.Black}C0), 0 2px 4px -1px var(--shadow-color, ${GoldenV2Palette.Black}C0)`,
};

const General: ColorDefinition['General'] = {
  Bonus: GoldenV2Palette.Yellow,
  BonusContrast: GoldenV2Palette.Dark500,
};

const Layout: ColorDefinition['Layout'] = {
  Background: GoldenV2Palette.Dark500,
  BrandBorder: Primitive.Primary,
  BrowserTheme: GoldenV2Palette.Dark500,
  LoadingColor: Primitive.Primary,
  Overlay: GoldenV2Palette.Dark900 + 'CC',
  Sidebar: {
    Background: GoldenV2Palette.Dark500,
    Foreground: GoldenV2Palette.White,
  },
};

const Hero: ColorDefinition['Hero'] = {
  Heading: GoldenV2Palette.White,
  Text: GoldenV2Palette.White + 'CC',
  TextHighlight: Primitive.Secondary,

  Button: {
    Solid: Primitive.Primary,
    Rest: Primitive.Gradient,
    Hover: Primitive.GradientTint,
    Active: Primitive.GradientTint,
    Text: Primitive.GradientContrast,
  },
};

export const Color: ColorDefinition = {
  TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,
};
