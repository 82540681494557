import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  BackgroundBlur: Color.Layout.Background + 'B0',
  Background: Color.Layout.Background + 'F2',

  Icon: Color.TextColors.BodyText,
  IconFocusBackground: Color.Primitive.Secondary + '20',
  IconFocusCornerRadius: Color.CornerRadius.Large,

  NotificationBackground: Color.Primitive.Secondary,
  NotificationText: Color.Primitive.SecondaryContrast,
};
