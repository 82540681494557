import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  TrackBackgroundChecked: `${Color.Primitive.Accent}20`,
  TrackBorder: `inset 0 0 3px -1px ${Color.Primitive.Accent}20`,

  ThumbBackground: Color.TextColors.HighlightedText,
  ThumbBackgroundChecked: Color.Primitive.Accent,
  ThumbBoxShadow: `0 0 5px 0 ${Color.Primitive.Accent}80`,
};
