import type { FC } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { graphql, useFragment } from 'react-relay/hooks';
import dynamic from 'next/dynamic';
import { useTranslation } from '@pafcloud/i18n';
import { ButtonLink, Link, Image } from '@pafcloud/base-components';
import { useConfig, useIsLoggedIn } from '@pafcloud/contexts';
import { useFlowRouter } from '@pafcloud/flow-router';
import { Breakpoint, Color, LayoutProps, deviceWithBackdropFilter, deviceWithHover } from '@pafcloud/style';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';
import { MessageMenu } from '../menus/message-menu';
import type { HeaderContentGoldenV2_content$key } from './__generated__/HeaderContentGoldenV2_content.graphql';
import { StyledHeader } from './shared';
import type { HeaderContentProps } from './shared';
import { HeaderMenu } from './HeaderMenu';
import { HeaderSearchBar } from './HeaderSearchBar';

const ProfileMenu = dynamic(() => import('../menus/profile-menu/ProfileMenu'), { ssr: false });

const contentFragment = graphql`
  fragment HeaderContentGoldenV2_content on Query {
    ...HeaderMenu_content
    ...HeaderSearchBar_data
    ...ProfileMenu_data
  }
`;

const headerBreakpoint = Breakpoint.LaptopOrLarger;
const inlinePadding = 'var(--full-width-margin)';
const maskFadeWidth = 'min(32px, var(--full-width-margin))';

const scrollbar = css({
  scrollbarWidth: 'none',
  scrollbarColor: 'rgba(255 255 255 / 30%) transparent',

  '::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
    visibility: 'hidden',
  },
  '::-webkit-scrollbar-track': {
    background: 'rgba(0 0 0 / 0%)',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '0.5rem',
    background: 'rgba(255 255 255 / 0%)',
    boxShadow: 'unset',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: Color.Primitive.Primary,
    boxShadow: 'unset',
  },

  [deviceWithHover]: {
    ':hover': {
      scrollbarWidth: 'thin',
    },
    '&:hover::-webkit-scrollbar': {
      visibility: 'visible',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      background: 'rgba(255 255 255 / 30%)',
    },
  },
});

const Scroll = styled.div(scrollbar, {
  overflowX: 'auto',
  overflowY: 'hidden',
  maxWidth: '100%',
  paddingInline: maskFadeWidth,
  maskImage: `
    linear-gradient(
      to left,
      transparent 2%,
      transparent 0%,
      black ${maskFadeWidth},
      black calc(100% - ${maskFadeWidth}),
      transparent 98%,
      transparent 100%
    )
  `,
});

const Header = styled(StyledHeader)({
  position: 'sticky',
  display: 'grid',
  overflow: 'hidden',
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: '1fr 1fr',
  maxWidth: '100%',
  height: 'unset',
  minHeight: LayoutProps.HeaderHeight,

  backgroundColor: `${Color.Layout.Background}F2`,
  boxShadow: 'none',

  [deviceWithBackdropFilter]: {
    backgroundColor: `${Color.Layout.Background}E6`,
    backdropFilter: 'blur(10px)',
  },

  [headerBreakpoint]: {
    gridTemplateColumns: '1fr auto 1fr',
    gridTemplateRows: '1fr',
  },

  // Subtle glow
  '::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    backgroundImage: `radial-gradient(ellipse at left -2%, ${Color.Primitive.Accent}10, transparent 33%, transparent)`,
    backgroundSize: '1000px 100px',
    backgroundRepeat: 'no-repeat',
    pointerEvents: 'none',
  },
});

const LeftSection = styled.div({
  marginLeft: inlinePadding,
  marginRight: 8,
  minWidth: 128,
});

const CategoryMenu = styled.div({
  overflow: 'hidden', // make sure menu scrolls when content is wider than the page.
  gridColumn: '1 / span 2',
  height: LayoutProps.HeaderHeight,
  borderTop: `1px solid rgba(255,255,255,0.1)`,

  nav: {
    height: LayoutProps.HeaderHeight, // same height as parent to avoid extra space created by scrollbar

    '> *': {
      whiteSpace: 'nowrap',
    },
  },

  [headerBreakpoint]: {
    gridColumn: 'auto',
    borderTop: 'unset',
  },
});

const RightSection = styled.div({
  justifySelf: 'flex-end',
  display: 'flex',
  alignItems: 'center',
  marginRight: inlinePadding,

  [headerBreakpoint]: {
    order: 3,
  },
});

const LoggedInContent = styled.div({
  display: 'flex',
  marginRight: -20, // magic number (half of ButtonContent) to align the profile menu icon with game list
});

const LogoLink = styled(Link)({
  display: 'block',
  height: LayoutProps.HeaderHeight,
  paddingBlock: 8,
});

const GoldenBullLogo = styled(Image)({
  display: 'block',
  width: 'auto',
  maxWidth: '100%',
  height: 32,
  objectFit: 'contain',
});

const HeaderButtonLink = styled(ButtonLink)({
  minWidth: '6rem',
});

export const HeaderContent: FC<HeaderContentProps> = ({ isMainMenuOpen, setMainMenuOpen, ...props }) => {
  const content = useFragment<HeaderContentGoldenV2_content$key>(contentFragment, props.content);
  const { t } = useTranslation(['header', 'profile']);
  const { getFlowUrl } = useFlowRouter();
  const isLoggedIn = useIsLoggedIn();
  const { env } = useConfig();

  const flowUrlType = $buildEnv.site === 'goldenbull.se' && env !== 'prod' ? 'deposit' : 'login';

  return (
    <Header>
      <LeftSection>
        <LogoLink aria-label={t('logo.aria-label')} href="/">
          <GoldenBullLogo src="/static/images/goldenbull-horizontal-logo.png" alt="" width={207} height={32} />
        </LogoLink>
      </LeftSection>

      <RightSection>
        <HeaderSearchBar data={content} />
        {isLoggedIn && (
          <LoggedInContent>
            <MessageMenu />
            <ProfileMenu data={content} />
          </LoggedInContent>
        )}

        {!isLoggedIn && (
          <HeaderButtonLink
            href={getFlowUrl(flowUrlType)}
            aria-label={t('header-button.logged-out.aria-label')}
            size="default"
            variant="primary"
            type="button"
            data-testid="header-action"
          >
            {t('header-button.logged-out.text')}
          </HeaderButtonLink>
        )}
      </RightSection>

      <CategoryMenu>
        <Scroll>
          <HeaderMenu content={content} />
        </Scroll>
      </CategoryMenu>
    </Header>
  );
};
