var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"goldenbull.se@13.6.2"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import { init, browserTracingIntegration } from '@sentry/nextjs';
import { captureConsoleIntegration } from '@sentry/integrations';
import type { ClientConfig } from '@pafcloud/config';
import { $buildEnv } from '@pafcloud/config/src/buildEnv';

function getOriginalMessage(exception?: unknown) {
  if (typeof exception === 'string') {
    return exception;
  }

  if (exception == null || typeof exception !== 'object') {
    return null;
  }

  if (!('message' in exception) || typeof exception.message !== 'string') {
    return null;
  }
  return exception.message;
}

export function initializeSentry(config: ClientConfig) {
  init({
    dsn: config.sentryDSN,
    environment: config.env,
    release: `${$buildEnv.site}@${config.version}`,
    allowUrls: [config.baseUrl],
    integrations: [
      browserTracingIntegration(),
      captureConsoleIntegration({
        levels: ['error', 'warn'],
      }),
    ],
    tracesSampleRate: 0.02,
    maxBreadcrumbs: 30,
    beforeSend: (event, info) => {
      if (config.env === 'local') {
        if (event.level === 'error' && config.debugSentry === 'true') {
          console.debug(`Sentry Event:\n${JSON.stringify(event, null, 2)}`);
        }
        return null;
      }

      // this is to filter vulnerability-testing errors
      // and to make sure that the check does not leak to the client scripts
      if (process.env.SERVER) {
        if (!event.request || !event.request.headers) {
          return null;
        }

        if (event.request.headers['paf-vulnerability-testing'] === 'appcheck') {
          return null;
        }
      }

      if (event.level === 'info' || event.level === 'debug') {
        return null;
      }

      // Reduce noise
      if (shouldExcludeMessage(event.message)) {
        return null;
      }

      // Reduce more noise
      const originalException = getOriginalMessage(info.originalException);
      if (shouldExcludeMessage(originalException)) {
        return null;
      }

      return event;
    },
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}

function shouldExcludeMessage(message?: string | null) {
  if (!message) {
    return false;
  }

  return (
    message.includes('[Meta Pixel]') ||
    message.includes('- Duplicate Pixel ID:') ||
    message.includes('static/app_content/features/serviceworker/js/sw.js') ||
    message.includes('Cancel rendering route') ||
    message.includes('Loading initial props cancelled') ||
    message.includes('Unable to process transaction') ||
    // ↓ webpack warnings ↓
    message.includes('Colors not specified') ||
    message.includes('Content not specified') ||
    message.includes('Assets not specified') ||
    message.includes('BonusMenuStyles not specified') ||
    message.includes('Style not specified') ||
    message.includes('Styles not specified') ||
    message.includes('LanguageCodeToLocaleMap not specified') ||
    message.includes('BaseFont not specified') ||
    message.includes('Font not specified') ||
    message.includes('FontHeadingSize not specified') ||
    message.includes('FontTextSize not specified') ||
    message.includes('This error can be ignored')
  );
}

initializeSentry(window.pafAppConfig);
